import React from "react";
import { Navigate, Route } from "react-router-dom";
import { apiDocsPlugin, ApiExplorerPage } from "@backstage/plugin-api-docs";
import { CatalogEntityPage, CatalogIndexPage, catalogPlugin } from "@backstage/plugin-catalog";
import { catalogImportPlugin } from "@backstage/plugin-catalog-import";
import { scaffolderPlugin } from "@backstage/plugin-scaffolder";
import { orgPlugin } from "@backstage/plugin-org";
import { SearchPage } from "@backstage/plugin-search";
import { TechDocsIndexPage, techdocsPlugin, TechDocsReaderPage } from "@backstage/plugin-techdocs";
import { UserSettingsPage } from "@backstage/plugin-user-settings";
import { apis } from "./apis";
import { entityPage } from "./components/catalog/EntityPage";
import { searchPage } from "./components/search/SearchPage";
import { Root } from "./components/Root";

import { AlertDisplay, OAuthRequestDialog } from "@backstage/core-components";
import { createApp } from "@backstage/app-defaults";
import { AppRouter, FlatRoutes } from "@backstage/core-app-api";
import { CatalogGraphPage } from "@backstage/plugin-catalog-graph";

import { SignInPage } from "@backstage/core-components";
import { microsoftAuthApiRef } from "@backstage/core-plugin-api";
import { ReportIssue } from "@backstage/plugin-techdocs-module-addons-contrib";
import { TechDocsAddons } from "@backstage/plugin-techdocs-react";

const app = createApp({
    components: {
        SignInPage: (props) => (
            <SignInPage
                {...props}
                provider={{
                    id: "microsoft-auth-provider",
                    title: "Education First",
                    message: "Sign in using your ef.com email",
                    apiRef: microsoftAuthApiRef,
                }}
            />
        ),
    },
    apis,
    bindRoutes({ bind }) {
        bind(catalogPlugin.externalRoutes, {
            createComponent: scaffolderPlugin.routes.root,
            viewTechDoc: techdocsPlugin.routes.docRoot,
        });
        bind(apiDocsPlugin.externalRoutes, {
            registerApi: catalogImportPlugin.routes.importPage,
        });
        bind(orgPlugin.externalRoutes, {
            catalogIndex: catalogPlugin.routes.catalogIndex,
        });
    },
});

const routes = (
    <FlatRoutes>
        <Route path="/" element={<Navigate to="catalog" />} />
        <Route path="/catalog" element={<CatalogIndexPage />} />
        <Route path="/catalog/:namespace/:kind/:name" element={<CatalogEntityPage />}>
            {entityPage}
        </Route>
        <Route path="/docs" element={<TechDocsIndexPage />} />
        <Route path="/docs/:namespace/:kind/:name/*" element={<TechDocsReaderPage />}>
            <TechDocsAddons>
                <ReportIssue />
            </TechDocsAddons>
        </Route>
        <Route path="/api-docs" element={<ApiExplorerPage />} />
        <Route path="/search" element={<SearchPage />}>
            {searchPage}
        </Route>
        <Route path="/settings" element={<UserSettingsPage />} />
        <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    </FlatRoutes>
);

export default app.createRoot(
    <>
        <AlertDisplay />
        <OAuthRequestDialog />
        <AppRouter>
            <Root>{routes}</Root>
        </AppRouter>
    </>
);
